import React, { useEffect, useState } from 'react';
import styles from './Code.module.scss';
import NavBar from 'components/navBar/NavBar';
import { RouteComponentProps } from 'react-router';
import { useRecordAPI } from 'store/network/RecordService';
import image from 'components/assets/images/Illustration.svg';
import Button, { ButtonType } from 'components/button/Button';
import inputStyle from '../../components/input/Input.module.scss'
import Input from 'components/input/Input';
import { notificationError } from 'components/notification/Notification';
import strings from '../../values/strings'
import { useSelector } from 'react-redux';
import { IAppReducer } from 'store/reducers/AppReducer';
import Variables from '../../utils/Variables';

interface RouterProps {
	identifier: string
}

interface Props extends RouteComponentProps<RouterProps> { }

const Code: React.FC<Props> = ({ history, match: { params: { identifier } } }) => {

	const appContext = useSelector((state: IAppReducer) => state.app.appContext)

	let [isLoading, setIsLoading] = useState<boolean>(false)
	let [showError, setShowError] = useState<boolean>(false)

	const [record, error, getRecord] = useRecordAPI()
	const [code, setCode] = useState()

	const checkRecord = () => {
		setIsLoading(true)
		getRecord(identifier, code);
	}

	useEffect(() => {
		setIsLoading(false)
		if (record) history.push(`/${identifier}/${code}/`)
	}, [record])

	useEffect(() => {
		setIsLoading(false)
		if (error) notificationError(error['detail'])
	}, [error])



	return (
		<div>
			<div className={styles.navbarContainer}>
				<NavBar />
			</div>

			<div className={styles.container}>
				<div className={styles.viewContainer}>
					<img
						src={Variables.illustration(appContext)}
						className={styles.image} />

					<div className={styles.viewContent}>
						<p className={styles.title}>{strings.code.title}</p>
						<p className={styles.subtitle}>{strings.code.subtitle}</p>

						<div>
							<form className={inputStyle.form} onSubmit={(e) => { e.preventDefault(); checkRecord() }}>
								<Input
									label={strings.code.inputLabel}
									placeholder={strings.code.inputPlaceholder}
									value={code}
									autoFocus={true}
									readOnly={isLoading}
									onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
										if (event) {
											setCode(event.target.value)
											setShowError(false)
										}
									}}
								/>
							</form>
						</div>

						<div className={styles.buttonContainer}>
							<Button
								type={ButtonType.primary}
								title={strings.code.actionContinue}
								isEnabled={code}
								isLoading={isLoading}
								onClick={checkRecord}
							/>
						</div>

					</div>

				</div>
			</div>

		</div>
	)
}

export default Code;
