
import { store } from '../index';
import { AppContext } from 'store/reducers/AppReducer';
import illustrationCollega from 'components/assets/images/Illustration_Collega.svg';
import illustrationAIGA from 'components/assets/images/Illustration_AIGA.svg';
import logoAIGA from 'components/assets/images/Logo_AIGA.svg';
import logoCollega from 'components/assets/images/Logo_Collega.svg';
import buttonStyle from 'components/button/Button.module.scss'


export default class Variables {

    static illustration = (context: AppContext) => {
        switch (context) {
            case AppContext.AIGA: return illustrationAIGA
            case AppContext.Collega: return illustrationCollega
        }
    }

    static logo = (context: AppContext) => {
        switch (context) {
            case AppContext.AIGA: return logoAIGA
            case AppContext.Collega: return logoCollega
        }
    }

    static buttonPrimaryStyle = (context: AppContext) => {
        switch (context) {
            case AppContext.AIGA: return buttonStyle.buttonAIGA
            case AppContext.Collega: return buttonStyle.buttonCollega
        }
    }

    static buttonClearStyle = (context: AppContext) => {
        switch (context) {
            case AppContext.AIGA: return buttonStyle.buttonClearAIGA
            case AppContext.Collega: return buttonStyle.buttonClearCollega
        }
    }

}
