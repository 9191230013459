import axios from 'axios';


class NetworkResponse {
    data?: any
    error?: any

    constructor(data?: any, error?: any) {
        this.data = data
        this.error = error
    }
}


export enum NetworkMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}


export default class Network {

    private static BASE_URL = 'https://servizi.collegaonline.it/api/';
    // private static BASE_URL = 'http://fuoricitta.dyndns.org:8000/api/';

    private static buildURL = (endpoint: string): string => {
        return Network.BASE_URL + endpoint
    }

    static request = async (method: any, endpoint: string, data?: any, params?: any): Promise<NetworkResponse> => {
        axios.defaults.headers['Content-Type'] = 'application/json'
        
        let request = axios({
            method: method,
            url: Network.buildURL(endpoint),
            data: (data) ? JSON.stringify(data) : null,
            params: params
        });

        try {
            let response = await request
            return new NetworkResponse(response.data, undefined)
        } catch (error) {
            try {
                return new NetworkResponse(undefined, error.response.data)
            } catch (error) {
                return new NetworkResponse(undefined, 'generic error')
            }
        }
    }
}
