import React from 'react';
import './NotFound.module.scss';
import { RouteComponentProps } from 'react-router';
import style from './NotFound.module.scss';
import NavBar from 'components/navBar/NavBar';
import { Spinner } from 'react-bootstrap';

interface Props extends RouteComponentProps { }

const NotFound: React.FC<Props> = () => {

	return (
		<div>
			<div className={style.navbarContainer}>
				<NavBar />
			</div>

			<div className={style.container}>
				<h1 className={style.error}>404</h1>
				<div className={style.title}>Siamo spiacenti, pagina non trovata!</div>
				<div className={style.message}>La pagina che stai cercando non è al momento raggiungibile.</div>
			</div>

		</div>
	)
}

export default NotFound;
