import { useState } from "react"
import Network, { NetworkMethod } from "store/network/Network"
import { Record } from "store/types/Record"


export const useRecordAPI = (): [Record, any, (identifier: string, code: string) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    
    let request = async (identifier: string, code: string) => {
        let endpoint = `public-records/${identifier}/${code}/`
        
        let response = await Network.request(NetworkMethod.GET, endpoint, null, null)
        setError(response.error)

        if (response.data) {
            setResponse(response.data)
        }
    }

    return [response, error, request]
}