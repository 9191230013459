import React from 'react';
import './App.module.scss';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css'

import { RootRouter } from 'router/RootRouter';
import { useContextAPI } from 'store/network/AppService';
import { useEffect } from 'react';
import { useDispatch, Provider } from 'react-redux';
import { setAppContext } from 'store/reducers/AppReducer';


const App: React.FC = () => {

	let [contextResponse, , contextRequest] = useContextAPI()
	let dispatch = useDispatch()


	useEffect(() => {
		let id = window.location.pathname.split('/', 2).join('')
		contextRequest(id)
	}, [])

	useEffect(() => {
		if (contextResponse) dispatch(setAppContext(contextResponse))
	}, [contextResponse])

	return (
		<div className="app-container">
			<ReactNotification />
			<RootRouter />
		</div>
	)
};

export default App;
