import { useState } from "react"
import Network, { NetworkMethod } from "store/network/Network"
import { Record } from "store/types/Record"
import { Block } from "store/types/Block"


export const useCreateBlockAPI = (): [Block, any, (record: Record) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    
    let request = async (record: Record) => {
        let endpoint = `public-records/${record.identifier}/${record.code}/blocks/`
        let data = {
            editing: true
        }

        let response = await Network.request(NetworkMethod.POST, endpoint, data, null)
        setError(response.error)

        if (response.data) {
            setResponse(response.data)
        }
    }

    return [response, error, request]
}

export const useUpdateBlockAPI = (): [Block, any, (record: Record, block: Block) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    
    let request = async (record: Record, block: Block) => {
        let endpoint = `public-records/${record.identifier}/${record.code}/blocks/${block.hash}/`
        let data = block

        let response = await Network.request(NetworkMethod.PUT, endpoint, data, null)
        setError(response.error)

        if (response.data) {
            setResponse(response.data)
        }
    }

    return [response, error, request]
}

export const useDeleteBlockAPI = (): [Block, any, (record: Record, block: Block) => void] => {
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    
    let request = async (record: Record, block: Block) => {
        let endpoint = `public-records/${record.identifier}/${record.code}/blocks/${block.hash}/`

        let response = await Network.request(NetworkMethod.DELETE, endpoint, null, null)
        await setError(response.error)

        setResponse(error === undefined ? new Date() : undefined)
    }

    return [response, error, request]
}