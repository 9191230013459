import React, { useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useRecordAPI } from 'store/network/RecordService';
import ViewBlock from 'components/blocks/view-block/ViewBlock';
import EditBlock from 'components/blocks/edit-block/EditBlock';
import { useCreateBlockAPI, useUpdateBlockAPI, useDeleteBlockAPI } from 'store/network/BlockService';
import { Block } from 'store/types/Block';
import Dialog from 'components/dialog/Dialog';
import Button, { ButtonType } from 'components/button/Button';
import NavBar from 'components/navBar/NavBar';
import style from './Record.module.scss';
import strings from 'values/strings';
import { useInterval } from '../../hooks/Hooks';
import { notificationError } from 'components/notification/Notification';
import Spinner from 'react-bootstrap/Spinner'


interface RouterProps {
	identifier: string
	code: string
}

interface Props extends RouteComponentProps<RouterProps> { }

let Record: React.FC<Props> = ({ history, match: { params: { identifier, code } } }) => {

	let [record, error, getRecord] = useRecordAPI()
	let [createdBlock, createBlockError, _createBlock] = useCreateBlockAPI()
	let [updatedBlock, updatedBlockError, _updateBlock] = useUpdateBlockAPI()
	let [deletedBlock, , _deleteBlock] = useDeleteBlockAPI()

	let [currentBlock, setCurrentBlock] = useState<Block>()
	let [isLoading, setIsLoading] = useState<boolean>(false)
	let [isEditing, setIsEditing] = useState<boolean>(false)

	let [showDonePopup, setShowDonePopup] = useState<boolean>(false)
	let [showCancelPopup, setShowCancelPopup] = useState<boolean>(false)
	let [showStartPopup, setShowStartPopup] = useState<boolean>(false)
	let [showLockedPopup, setShowLockedPopup] = useState<boolean>(false)


	useInterval(() => {
		updateBlock()
	}, isEditing ? 1000 * 30 : null) // 30 seconds



	let handlePreventDefault = (event: BeforeUnloadEvent) => {
		event.preventDefault()
		event.returnValue = ''
		return ''
	}

	useEffect(() => { // TODO: 
		if (isEditing) {
			window.addEventListener('beforeunload', handlePreventDefault)
			
			return () => {
				window.removeEventListener('beforeunload', handlePreventDefault)
			}
		}
	}, [isEditing])


	useEffect(() => {
		getRecord(identifier, code)
	}, [identifier, code])

	useEffect(() => {
		setIsLoading(false)
		if (record) {
			setShowLockedPopup(record.is_editing)
			setIsEditing(record.is_editing)
		}
	}, [record])

	useEffect(() => {
		setIsLoading(false)
		if (error) history.push(`/${identifier}/${code}/404/`)
	}, [error])

	useEffect(() => {
		setIsLoading(false)
		let error = updatedBlockError || undefined
		if (error) notificationError(error['detail'])
	}, [updatedBlockError])

	useEffect(() => {
		setIsLoading(false)
		if (createBlockError) getRecord(identifier, code)
	}, [createBlockError])

	useEffect(() => {
		setIsLoading(false)
		if (createdBlock) {
			setCurrentBlock(createdBlock)

			setIsEditing(true)
		}
	}, [createdBlock])

	useEffect(() => {
		if (deletedBlock) {
			setCurrentBlock(undefined)

			setIsEditing(false)
		}
	}, [deletedBlock])

	useEffect(() => {
		if (updatedBlock && !updatedBlock.editing) {  // Use updatedBlock only on last change
			setCurrentBlock(undefined)
			getRecord(identifier, code)
		}
	}, [updatedBlock])

	useEffect(() => {
		if (currentBlock && !currentBlock.editing) {
			_updateBlock(record, currentBlock!)

			setIsEditing(false)
		}
	}, [currentBlock])



	const createBlock = () => {
		setIsLoading(true)
		_createBlock(record)
	}

	const updateBlock = () => {  // Timer Function
		if (currentBlock) _updateBlock(record, currentBlock)
	}

	const changeBlock = (text: string) => {
		setCurrentBlock({ ...currentBlock!, text: text })
	}

	const submitBlock = () => {
		setCurrentBlock({ ...currentBlock!, editing: false })
	}

	const deleteBlock = () => {
		_deleteBlock(record, currentBlock!)
	}



	const renderEditor = () => {
		if (record.is_editing) {
			return <div className={style.message}>
				<p className={style.subtitle}>{strings.records.busy}</p>
			</div>

		} else if (currentBlock) {
			return (
				<EditBlock
					onChange={changeBlock}

					// On Cancel unlock block sending empty string
					onCancel={() => {
						setShowCancelPopup(true)
					}}

					// On Submit open dialog with confirmation
					onSubmit={() => {
						setShowDonePopup(true)
					}} />
			)

		} else {
			return (
				<div className={style.buttonContainer}>
					<Button
						type={ButtonType.primary}
						title={strings.records.actionWrite}
						isEnabled={true}
						isLoading={isLoading}
						onClick={() => setShowStartPopup(true)} />
				</div>
			)
		}
	}

	if (record) {
		return (
			<div>
				{/* Confirmation Done Dialog */}
				<Dialog
					show={showDonePopup}
					title={strings.dialog.done.title}
					text={strings.dialog.done.text}
					onConfirm={() => {
						setShowDonePopup(false)
						submitBlock()
					}}
					onCancel={() => { setShowDonePopup(false) }} />

				{/* Confirmation Cancel Dialog */}
				<Dialog
					show={showCancelPopup}
					title={strings.dialog.cancel.title}
					text={strings.dialog.cancel.text}
					onConfirm={() => {
						setShowCancelPopup(false)
						deleteBlock()
					}}
					onCancel={() => { setShowCancelPopup(false) }} />

				{/* Confirmation Start Dialog */}
				<Dialog
					show={showStartPopup}
					title={strings.dialog.start.title}
					text={strings.dialog.start.text}
					onConfirm={() => {
						setShowStartPopup(false)
						createBlock()
					}}
					onCancel={() => { setShowStartPopup(false) }} />

				{/* Locked block Dialog */}
				<Dialog
					show={showLockedPopup}
					isLoading={isLoading}
					title={strings.dialog.locked.title}
					text={strings.dialog.locked.text}
					onConfirm={() => {
						setShowLockedPopup(false)
					}} />

				<div className={style.navbarContainer}>
					<NavBar />
				</div>

				<div className={style.container}>
					<p className={style.title}>{strings.records.recordTitle} {record.identifier}</p>
					{record.blocks.map((block, index) => {
						return <ViewBlock key={index} block={block} />
					})}
					{renderEditor()}
				</div>
			</div>
		)

	} else {
		return (
			<div className={style.loaderContainer}>
				<Spinner className={style.loader} animation="grow" role="status" />
			</div>
		)
	}
}

export default Record;
