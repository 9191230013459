import React from 'react';
import style from './ViewBlock.module.scss';
import { Block } from 'store/types/Block';


interface Props {
	block: Block
}

const ViewBlock: React.FC<Props> = ({ block }) => {

	return (
		<div className={style.container}>
			<div className={style.text}>{block.text.split("\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
		</div>
	)
}

export default ViewBlock;
