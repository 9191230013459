import React, { ChangeEvent, FocusEvent, Fragment } from 'react';
import classNames from 'classnames';

interface InputProps {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value?: string | undefined;
  error?: string;
  autoFocus?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => any;
}

const Input: React.FC<InputProps> = (props) => {
  return (
    <Fragment>
      {props.label && (<label>{props.label}</label>)}
      <input
        type={props.type}
        name={props.name}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus}
        className={classNames({ 'input-error': props.error })}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.error && <p className={'error'}>{props.error}</p>}
    </Fragment>
  );
};

export default Input;
