import React from 'react';
import style from './Dialog.module.scss'
import Button, { ButtonType } from 'components/button/Button';
import { Modal } from 'react-bootstrap';
import strings from 'values/strings';


interface Props {
    show: boolean
    title: string
    text: string
    isLoading?: boolean
    onConfirm: () => void
    onCancel?: () => void
}

const Dialog: React.FC<Props> = (props) => {

    let modalStyle = () => {
        return window.innerWidth >= 1024 ? style.modalBrowser : style.modal
    }

    return (
        <Modal
            {...props}
            dialogClassName={modalStyle()}
            className={style.modalBackdrop}
            size='sm'
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <div className={style.container} >
                <h3 className={style.title}>{props.title}</h3>
                <p className={style.text}>{props.text}</p>

                <div className={style.actionsContainer}>
                    <Button
                        title={props.onCancel ? strings.dialog.actionConfirm : strings.dialog.actionOkay}
                        type={ButtonType.primary}
                        isEnabled={true}
                        isLoading={props.isLoading || false}
                        onClick={props.onConfirm} />

                    {props.onCancel &&
                        <Button
                            title={strings.dialog.actionCancel}
                            type={ButtonType.clear}
                            isEnabled={true}
                            isLoading={false}
                            onClick={props.onCancel} />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default Dialog;