
const SET_APP_CONTEXT = 'SET_APP_CONTEXT';

export enum AppContext {
  AIGA = 'aiga', Collega = 'collega'
}

export interface IAppReducer {
  app: IAppReducerState;
}

export interface IAppReducerState {
  appContext: AppContext;
}


// ACTIONS

export function setAppContext(appContext: string) {
  return async (dispatch: any) => {

    await dispatch({
      type: SET_APP_CONTEXT,
      appContext: appContext
    });
  };
}


const INITIAL_STATE: IAppReducerState = {
  appContext: AppContext.Collega
};

// REDUCER
export default function (state: IAppReducerState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_APP_CONTEXT:

    console.log(action.appContext)
      return {
        ...state,
        appContext: action.appContext
      };
    default:
      return state;
  }
}