import { Route, Switch, BrowserRouter } from "react-router-dom";
import React from "react";
import Code from "pages/code/Code";
import Record from "pages/record/Record";
import NotFound from "pages/others/NotFound";


export const RootRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/:identifier/" component={Code} />
                <Route exact path="/:identifier/:code/" component={Record} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}
