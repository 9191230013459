import { useState } from "react"
import Network, { NetworkMethod } from "store/network/Network"
import { AppContext } from "store/reducers/AppReducer"


export const useContextAPI = (): [AppContext, any, (identifier: string) => void] => {
    const [response, setResponse] = useState<any>()
    const [error, setError] = useState()

    let request = async (identifier: string) => {
        let endpoint = `public-records/${identifier}/`

        let response = await Network.request(NetworkMethod.GET, endpoint, null, null)
        setError(response.error)

        if (response.data) {
            console.log(response.data)
            setResponse(response.data.slug)
        }
    }

    return [response, error, request]
}