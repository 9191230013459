import React from 'react'
import styles from './NavBar.module.scss'
import Variables from '../../utils/Variables';
import { useSelector } from 'react-redux';
import { IAppReducer } from 'store/reducers/AppReducer';

const NavBar: React.FC = () => {

    const appContext = useSelector((state: IAppReducer) => state.app.appContext)

    return (
        <div className={styles.navbar}>
            <img
                alt={appContext}
                src={Variables.logo(appContext)}
                className={styles.logo}
            />
        </div>
    )
}

export default NavBar;