import React from 'react';
import style from './EditBlock.module.scss';
import Button, { ButtonType } from 'components/button/Button';
import strings from 'values/strings';
import { useState } from 'react';


interface Props {
	onChange: (text: string) => void
	onCancel: () => void
	onSubmit: () => void
}

const EditBlock: React.FC<Props> = (props) => {

	let [text, setText] = useState()

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		let text = event.target.value
		props.onChange(text)
		setText(text)
	}

	return (
		<div>
			<div className={style.container}>
				<textarea className={style.inputText} onChange={handleChange} placeholder={strings.records.editBlock.textAreaPlaceholder} />
			</div>

			<div className={style.buttonContainer}>
				<Button
					type={ButtonType.clear}
					title={strings.records.editBlock.actionCancel}
					isEnabled={true}
					isLoading={false}
					onClick={props.onCancel} />

				<Button
					type={ButtonType.primary}
					title={strings.records.editBlock.actionDone}
					isEnabled={text && text.length > 0}
					isLoading={false}
					onClick={props.onSubmit} />
			</div>
		</div>
	)
}

export default EditBlock;
