import React from 'react';
import classNames from 'classnames';
import style from './Button.module.scss'
import { useSelector } from 'react-redux';
import { IAppReducer } from 'store/reducers/AppReducer';
import Variables from '../../utils/Variables';


export enum ButtonType {
    primary, clear
}

interface Props {
    type: ButtonType
    title: string
    isEnabled: boolean
    isLoading: boolean
    onClick: () => void
}

const Button: React.FC<Props> = (props) => {

    const appContext = useSelector((state: IAppReducer) => state.app.appContext)


    let buttonType = (type: ButtonType) => {
        switch (type) {
            case ButtonType.primary: return Variables.buttonPrimaryStyle(appContext)
            case ButtonType.clear: return Variables.buttonClearStyle(appContext)
        }
    }

    return (
        <button
            className={classNames(style.byttonPrimary, buttonType(props.type), { disabled: !props.isEnabled })}
            disabled={!props.isEnabled}
            onClick={props.onClick} >
            <span className={classNames({ unvisible: props.isLoading })}>{props.title}</span>
            {props.isLoading && <span className={style.buttonLoading} />}
        </button>
    )
}

export default Button;
