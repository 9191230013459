import { store } from 'react-notifications-component';


export let notificationError = (text: string) => {
    store.addNotification({
        title: "Errore!",
        message: text,
        type: "danger",
        insert: "top",
        width: 350,
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: false
        }
    });
}
