const strings = {

    error: {
        title: 'Errore!'
    },

    dialog: {
        actionConfirm: 'Conferma',
        actionOkay: 'Okay',
        actionCancel: 'Annulla',

        done: {
            title: 'Attenzione!',
            text: 'Non potrai più modificare il verbale dopo aver confermato.',
        },

        cancel: {
            title: 'Attenzione!',
            text: 'Stai annullando le modifiche apportate al verbale.',
        },

        start: {
            title: 'Attenzione!',
            text: 'La tua verbalizzazione potrà essere modificata solo da te. I tuoi colleghi non potranno modificare il verbale fino a quando non avrai confermato.',
        },

        locked: {
            title: 'Attenzione!',
            text: 'Il verbale è già in modifica da un tuo collega. Aspetta che finisca e riprova.',
        }
    },

    code: {
        title: 'Accedi al verbale',
        subtitle: 'Inserisci il codice che ti è stato inviato.',
        inputLabel: 'Codice di accesso',
        inputPlaceholder: 'Inserisci il codice di accesso.',
        actionContinue: 'Continua'
    },

    records: {
        busy: 'Un tuo collega sta scrivendo. Attendi qualche minuto',
        recordTitle: 'Verbale',
        actionWrite: 'Scrivi',

        editBlock: {
            textAreaPlaceholder: 'Inserisci i dati relativi al verbale',
            actionCancel: 'Annulla',
            actionDone: 'Fatto'
        }
    }

}

export default strings